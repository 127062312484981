/* TEBACKEND ROUTES */

let base_url = process.env.VUE_APP_DOMAIN_BACKEND;

if (base_url == 'http://localhost:8080' || base_url == 'http://127.0.0.1:8080' 
    || base_url == 'http://localhost:8000' || base_url == 'http://127.0.0.1:8000') {
        base_url='https://dev.services.smileandlearn.net';
}

const micro_v = '/tebackend/v1' ;

export const manteinance = base_url + '/tebackend' + '/health';
export const handleLogin = base_url + micro_v + '/login';
export const handleSSO = base_url + micro_v + '/sso';

export const getUser = base_url + micro_v + '/getUser';
export const editUser = base_url + micro_v + '/editUser';
export const deleteUserAccount = base_url + micro_v + '/deleteUserAccount';
export const saveAbilitiesRole = base_url + micro_v + '/saveAbilitiesRole';
export const getRoles = base_url + micro_v + '/roles';
export const saveRole = base_url + micro_v + '/saveRole';
export const deleteRole = base_url + micro_v + '/deleteRole';
export const saveSubject = base_url + micro_v + '/saveSubject';
export const deleteSubject = base_url + micro_v + '/deleteSubject';

export const dashboardStats = base_url + micro_v + '/dashboard';
export const dashboardContentStats = base_url + micro_v + '/dashboard/content-stats';
export const dashboardSchoolsByCountry = base_url + micro_v + '/dashboard/schools-by-country';
export const dashboardSchoolsByPartner = base_url + micro_v + '/dashboard/schools-by-partner';
export const dashboardSchoolsByPartnerId = base_url + micro_v + '/dashboard/schools-by-partner-id';
export const dashboardTransactions = base_url + micro_v + '/dashboard/transactions';
export const dashboardUsageBySchool = base_url + micro_v + '/dashboard/usage-by-school';
export const dashboardVersionReleases = base_url + micro_v + '/dashboard/versionReleases';

export const createDreamer = base_url + micro_v + '/dreamers';
export const getDreamer = base_url + micro_v + '/dreamers';
export const editDreamer = base_url + micro_v + '/dreamers';
export const deleteDreamer = base_url + micro_v + '/dreamers';

export const deleteDreamersMasive = base_url + micro_v + '/deleteMasive/dreamers';
export const searchDreamers = base_url + micro_v + '/dreamers/search';

export const createGroup = base_url + micro_v + '/groups';
export const getGroup = base_url + micro_v + '/groups';
export const editGroup = base_url + micro_v + '/groups';
export const deleteGroup = base_url + micro_v + '/groups';
export const deleteSelectedGroups = base_url + micro_v + '/deleteSelectedGroups';

export const createLicence = base_url + micro_v + '/createLicence';
export const fetchLicence = base_url + micro_v + '/fetchLicence';
export const searchLicences = base_url + micro_v + '/searchlicences';
export const getLicences = base_url + micro_v + '/licences';
export const editLicence = base_url + micro_v + '/editLicence';
export const recoverLicence = base_url + micro_v + '/recoverLicence';
export const deleteLicence = base_url + micro_v + '/deleteLicence';
export const deleteLicenceMasive = base_url + micro_v + '/deleteLicenceMasive';
export const regenerateSSO = base_url + micro_v + '/regenerateSSO';
export const regenerateLicencePassword = base_url + micro_v + '/regenerateLicencePassword';

export const exportGeneral = base_url + micro_v + '/export/general';
export const childPasswords = base_url + micro_v + '/child-passwords/';
export const contentRestriction = base_url + micro_v + '/content-restriction/';
export const handleCustomization = base_url + micro_v + '/customization/';

//son iguales?
export const moveGroup = base_url + micro_v + '/move-group';
export const schoolDreamerReasign = base_url + micro_v + '/school/dreamer/reassign';

//son iguales?
export const unlinkShared = base_url + micro_v + '/unlink-shared/';
export const schoolDreamerUnlink = base_url + micro_v + '/school/dreamer/unlink';
export const schoolGroupUnlink = base_url + micro_v + '/school/group/unlink';

export const recoverPassword = base_url + micro_v + '/recoverPassword';

export const createPartner = base_url + micro_v + '/partners/create';
export const fetchPartner = base_url + micro_v + '/partners/fetch';
export const editPartner = base_url + micro_v + '/partners/edit';
export const editPartnerLogo = base_url + micro_v + '/partners/edit/logo_upload';
export const getPartners = base_url + micro_v + '/partners';
export const searchPartners = base_url + micro_v + '/partners/search';
export const detachLicenceFromPartner = base_url + micro_v + '/detachAdminLicencePartner';
export const createPartnerToken = base_url + micro_v + '/partners/createToken';

export const getSchools = base_url + micro_v + '/schools';
export const createSchool = base_url + micro_v + '/school/create';
export const searchSchool = base_url + micro_v + '/schools/search';
export const fetchSchool = base_url + micro_v + '/fetchSchool';
export const editChangeTIC = base_url + micro_v + '/school/changeTic';
export const editSchool = base_url + micro_v + '/school/edit';
export const editSchoolLogo = base_url + micro_v + '/school/edit/logo_upload';
export const deleteSchool = base_url + micro_v + '/school/delete';
export const deleteMasiveSchool = base_url + micro_v + '/school/delete-masive';
export const recoverSchool = base_url + micro_v + '/school/recover';
export const deleteSchoolStructure = base_url + micro_v + '/school/deleteStructure';
// export const bulkDeleteSchoolLicences = base_url + micro_v + '/bulk/deleteLicenses';
export const deleteDreamersLicenses = base_url + micro_v + '/bulk/dreamers/deleteLicenses';
export const validateDreamersDeleteLicenses = base_url + micro_v + '/bulk/dreamers/validateLicenses';
export const dreamerDeleteLicensesPlantilla = base_url + micro_v + '/bulk/dreamers/plantilla';
export const updateSchoolLicences = base_url + micro_v + '/school/updateLicences';

export const getSchoolDreamers = base_url + micro_v + '/school/dreamers';
export const searchSchoolDreamers = base_url + micro_v + '/school/dreamers/search';
export const createSchoolDreamer = base_url + micro_v + '/school/dreamer/create';
export const editSchoolDreamer = base_url + micro_v + '/school/dreamer/edit';
export const fetchSchoolDreamer = base_url + micro_v + '/school/fetchDreamer';
export const deleteSchoolDreamer = base_url + micro_v + '/school/dreamer/delete';
export const deleteSelectedSchoolDreamer = base_url + micro_v + '/school/dreamer/deleteSelectedDreamers';
export const deleteAllSchoolDreamer = base_url + micro_v + '/school/dreamer/deleteAllDreamers';
export const recoverSchoolDreamer = base_url + micro_v + '/school/dreamer/recover';
export const createSchoolGroup = base_url + micro_v + '/school/group/create';
export const fetchSchoolGroup = base_url + micro_v + '/school/fetchGroup';
export const getSchoolGroups = base_url + micro_v + '/school/groups';
export const searchSchoolGroups = base_url + micro_v + '/school/groups/search';
export const editSchoolGroup = base_url + micro_v + '/school/group/edit';
export const deleteSchoolGroup = base_url + micro_v + '/school/group/delete';
export const deleteSelectedSchoolGroup = base_url + micro_v + '/school/group/deleteSelectedGroups';
export const getSchoolStudents = base_url + micro_v + '/school/students';
export const searchSchoolStudents = base_url + micro_v + '/school/students/search';
export const getSchoolTeachers = base_url + micro_v + '/school/teachers';
export const searchSchoolTeachers = base_url + micro_v + '/school/teachers/search';

export const bulkImportWizard = base_url + micro_v + '/bulk/import/wizard';
export const exportSchoolStructure = base_url + micro_v + '/bulk/export/schoolStructure';
export const exportSchoolPlantilla = base_url + micro_v + '/bulk/export/exportPlantilla';
export const importSchoolStructure = base_url + micro_v + '/bulk/import/validateSchoolStructure';
export const implementSchoolStructure = base_url + micro_v + '/bulk/import/importValidatedData';


export const raicesInitialData = base_url + micro_v + '/raices/inicial_data';
export const raicesFileUpload = base_url + micro_v + '/raices';
export const raicesSave = base_url + micro_v + '/raices/save';

export const getCatalogs = base_url + micro_v + '/resources/catalogs';
export const publishCatalogs = base_url + micro_v + '/resources/catalog/publish';
export const deleteCatalogs = base_url + micro_v + '/resources/catalog/delete';
export const getGuides = base_url + micro_v + '/resources/teaching_guides';
export const getTutorials = base_url + micro_v + '/resources/tutorials';

export const getDreamerUsage = base_url + micro_v + '/getDreamerUsage';
export const getUsagePerWeekday = base_url +micro_v + '/getUsagePerWeekday';
export const acceptPrivacy = base_url + micro_v + '/acceptPrivacy';

export const getUsagePerMonth = base_url +micro_v + '/getUsagePerMonth';
export const getDreamerDayTimeData = base_url +micro_v + '/getDreamerDayTimeData';
export const getDreamerContentUsagePerArea = base_url +micro_v + '/getDreamerContentUsagePerArea';
export const checkTokenReset = base_url + micro_v + '/checkTokenReset';
export const resetPassword = base_url + micro_v + '/resetPassword';
export const getDreamerLastTenContentUsage = base_url + micro_v + '/getDreamerLastTenContentUsage';
export const getContentsFeedback = base_url + micro_v + '/getContentsFeedback';

export const getPlayedContentsFeedback = base_url + micro_v + '/getPlayedContents/Feedback';
export const getPlayedContentsHierarchy = base_url + micro_v + '/getPlayedContents/Hierarchy';
export const getDayAndHourUsage = base_url + micro_v + '/getDayAndHourUsage';
